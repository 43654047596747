import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import influencerDataMap from './influencerData';
import { HeroImage } from '../../../components/heroes/HeroImage';
import { StandardSection } from '../../../layout/sections/StandardSection';
import { InstructionTitle } from '../../../components/ui/typography/InstructionTitle';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';
import { FloatingNav } from '../../../layout/components/FloatingNav';
import { Footer } from '../../../layout/components/Footer';
import Accordian from '../../../components/ui/Accordian/Accordian';
import { ImageComponent } from '../../../components/ui/images/ImageComponent';
import { API_URL, CDN_URL, scrollToElement } from '../../../js/Helper';
import { VideoComponent } from '../../../components/ui/VideoComponent';
import { Loading } from '../../../components/ui/Loading';
import { updateTheme } from '../../themes/js/utils';
import { useCard } from '../../cards/js';
import { useApiQuery } from '../../../hooks/customHooks';
import { modelsMap } from '../../../js/maps/modelsMap';
import CardNew2 from '../../cards/pages/CardNew2';
import { useHandleTierChange } from '../../cards/js/hooks';
import { LoyaltyCard } from '../../cards/components/LoyaltyCard/LoyaltyCard';
import { ButtonComponent } from '../../../components/ui/buttons';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--primary-colour);
  max-width: 100vw;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
//   background: var(--accent-colour);
  border: solid 1px rgba(255,255,255,0.2);
  border-radius: var(--border-radius-default);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 1.2rem;
  text-align: center;
  padding: 2rem;
`;

const IntroContainer = styled.div`
  height: 100vh;
  background: var(--primary-colour);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://i.scdn.co/image/ab677762000056b8f5dc9a6d9300bd0122398247');
    background-size: cover;
    background-position: center;
    filter: blur(5px) brightness(0.5);
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(--primary-colour), rgba(0, 0, 0, 0));
    z-index: 1;
  }
`;

const Content = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;

const LogoWrapper = styled.div`
  margin-top: 20px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const BottomContent = styled.div`
  margin-bottom: 20px;
`;

const InviteText = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 1rem;
`;

const MainTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1rem;
`;

const FeaturingText = styled.p`
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0.5rem;
`;

const ArtistName = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const AssociationText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.5;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const BrandName = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
`;

const CameraIcon = styled.span`
  font-size: 1.5rem;
`;

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

export const IntroSection = ({ title }) => {
  return (
    <IntroContainer>
      <Content>
        <LogoWrapper>
          <img src={'/loud-logo.png'} alt={'Loud Clothing Logo'} width={100} height={100} />
        </LogoWrapper>
        
        <MainContent>
          <InviteText>you're invited</InviteText>
          <MainTitle>
            Late Night<br />Dinner Party
          </MainTitle>
          <FeaturingText>Featuring</FeaturingText>
          <ArtistName>{'PartyNextDoor'}</ArtistName>
          {/* <LogoContainer>
            <CameraIcon>📷</CameraIcon>
            <LogoText>0 Life</LogoText>
          </LogoContainer> */}
        </MainContent>
        
        <BottomContent>
          <AssociationText>
            <span>In association with</span>
            <BrandName>0 Life</BrandName>
          </AssociationText>
        </BottomContent>
      </Content>
    </IntroContainer>
  );
};

const MenuSection = styled(StandardSection)`
  background-color: var(--secondary-colour);
  color: var(--text-colour);
  padding: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-large);
`;

const MenuCategory = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background: var(--accent-colour);
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ProposalsPage = () => {
  const { influencerType } = useParams();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get('section')
  const { setCard, setSelectedTier } = useCard();

  const { data, isLoading, isError, refetch } = useApiQuery(`${API_URL}/card/o-card`,
    data => {

      if(data.status === 'success') {
        updateTheme(data?.card?.default_tier?.theme)
        setCard(data.card)
        // updateTheme(data.card.default_tier.theme)
        setSelectedTier(data.card.default_tier)
      }

    },
    error => console.error(error.message)
    );

    console.log(data)

    // if (isLoading) {
    // return <Loading/>;
    // }

    if (isError) {
    return <h1>Error</h1>
    }

    if(!data) {
    return <Loading/>;
    }

  if(section) {
    scrollToElement(section)
  }


  if (!influencerType) {
    return <ErrorMessage>Error: Influencer type is missing.</ErrorMessage>;
  }

  const contentData = influencerDataMap[influencerType];

  if (!contentData) {
    navigate('/404', { replace: true });
    return null;
  }

  const menuItems = [
    { name: 'Overview', destination: `${window.location.pathname}?section=overview`, icon: 'IoInformationCircleOutline', order: 1 },
    { name: 'Highlights', destination: `${window.location.pathname}?section=highlights`, icon: 'IoStarOutline', order: 2 },
    { name: 'Deliverables', destination: `${window.location.pathname}?section=deliverables`, icon: 'IoListOutline', order: 3 },
    { name: 'Food', destination: `${window.location.pathname}?section=menu`, icon: 'IoFastFood', order: 4 },
    { name: 'Card', destination: `${window.location.pathname}?section=card`, icon: 'IoCardOutline', order: 5 },
  ];

  return (
    <PageContainer>
      {/* <HeroImage image={data.image} /> */}
      <IntroSection title={contentData.title} />



        <StandardSection>
        <VideoComponent data={{
        cdnUrl: `${CDN_URL}/videos/InfluencersVId.mp4`,
        thumbnail: `https://media.gq.com/photos/5e7b7d50c493b1000814cf4b/16:9/w_2560%2Cc_limit/gq%2520march%25202020%25205%2520Questions%2520We're%2520Asking%2520Ahead%2520of%2520new%2520PARTYNEXTDOOR%2520Album.jpg`,
      }} />
        </StandardSection>
    
      
      <ContentContainer>
        <StandardSection id="overview">
          <ImpactTitle main={'Loud x PartyNextDoor & You'} />
          <Paragraph>{contentData.overview}</Paragraph>
        </StandardSection>

        <StandardSection id="highlights" >
          <ImpactTitle header="Highlights" />
          <Accordian data={contentData.highlights.map((highlight, index) => {
            const [placeholder, description] = highlight.split(': ');
            return {
              placeholder: placeholder,
              description: description,
            };
          })} />
        </StandardSection>

        <StandardSection id="deliverables" style={{justifyContent: 'center', alignItems: 'center'}}>
          <ImpactTitle header="Deliverables" />
          <Paragraph>At least 1 post is to be fulfilled on the night, and another to be fulfilled post event.</Paragraph>
          <Paragraph>Specifics of the deliverables will be confirmed with you at least 24 hours before the event.</Paragraph>
          <Paragraph>All deliverables will be in association with 0 Life and/or Loud</Paragraph>
          <List>
            {contentData.deliverables.map((deliverable, index) => (
              <ListItem key={index} as={motion.li} {...fadeInUp}>
                {deliverable}
              </ListItem>
            ))}
          </List>
        </StandardSection>
      </ContentContainer>

      <MenuSection id="menu" style={{justifyContent: 'center', alignItems: 'center'}}>

        <ImpactTitle subHeader={'Food Served By'} header="Event Menu" />
      <ImageComponent  style={{marginBottom: '2rem 0'}} src={'/JerkJunctionLogo.png'} alt={'Loud Clothing Logo'} width={150} />

        <Paragraph>Indulge in our carefully curated menu for the evening:Let us know your choice when you sign up to the card below.</Paragraph>
        
        <InstructionTitle title={'Main Course Options'} />
        <List>
          <ListItem>Plantain chips with beetroot & scotch bonnet hummus and black truffle hummus</ListItem>
          <ListItem>Jerk Chicken Supreme, Lobster Mac & Cheese Ball & veg</ListItem>
          <ListItem>Jerk Glazed Salmon Fillet, Black Rice, pickled carrots & greens</ListItem>
        </List>
        
        <InstructionTitle title={'Dessert Options'} />
        <List>
          <ListItem>White chocolate cheesecake with mango & malibu syrup</ListItem>
          <ListItem>Carrot Cake with Cream cheese frosting & praline</ListItem>
        </List>
      </MenuSection>

      <StandardSection id="card" style={{justifyContent: 'center', alignItems: 'center', maxWidth: '100%', '@media (min-width: 1920px)': { maxWidth: '400px' }, backgroundColor: 'var(--accent-colour)'}}>
        <ImpactTitle header="Signup to 0 Life" />
        <Paragraph>This event is invite only. Signup to 0 Life to get access to this and other exclusive events.</Paragraph>
        <Paragraph>0 Life is a platform for brands and influencers to collaborate on exclusive, one-of-a-kind experiences.</Paragraph>
        <Paragraph>Address: 14 John Dalton St, Manchester, M2 6JR</Paragraph>
        <Paragraph>Date: 8th October 2024. </Paragraph>
        <Paragraph>Start time: 20:30</Paragraph>

        <LoyaltyCard
          card={data.card}
          tier={data.card.default_tier}
        />
        <ButtonComponent variant={'solid'} text="Signup" onClick={() => navigate('/c/o-card')} />
      </StandardSection>

  

     
      <FloatingNav items={menuItems} />
      <Footer />
    </PageContainer>
  );
};

export default ProposalsPage;