import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {FullPageAbsoluteContainer} from "../../../layout/styles/layoutStyles.js";
import SliderComponent from "../../../components/ui/SliderComponent.jsx";
import styled from "styled-components";
import {IntroSection} from "../../proposals/pages/ProposalsPage.jsx";
import {FloatingNav} from "../../../layout/components/FloatingNav/index.jsx";
import {ImpactTitle, Paragraph} from "../../../components/ui/typography/index.js";
import {convertToCurrency} from "../../../js/helpers/helpers.js";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {Footer} from "../../../layout/components/Footer.jsx";
import {getProductCollection} from "../../ecommerce/products/js/api/getProduct.js";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {ProductList} from "../../ecommerce/products/components/index.js";
import ProductCollection from '../../collections/components/ProductCollection.jsx';
import { Divider } from '../../../components/ui/Divider.jsx';
import { useDrawer } from '../../../js/hooks/context/DrawerContext.jsx';
import {scrollToElement} from "../../../js/Helper.js";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { IoInformationCircleOutline, IoGiftOutline } from "react-icons/io5";
import { useLocation } from 'react-router-dom';

const Container = styled.div`
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
`

const Section = styled.section`
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    
    strong {
        color: var(--accent-colour);
    }
`

const CampaignPurchaseLandingPage = ({}) => {
    const minFollowers = 10000; // Minimum followers
    const maxFollowers = 25000000; // Maximum followers
    const maxBudget = 30000; // £30,000 for 25 million followers
    const costPerFollower = 0.0025// maxBudget / maxFollowers; // This will be 0.0012
    const instagramEngagementRate = 0.01; // 1% engagement rate for Instagram
    const tiktokEngagementRate = 0.18; // 18% engagement rate for TikTok

    const [instagramFollowers, setInstagramFollowers] = useState(minFollowers);
    const [tiktokFollowers, setTiktokFollowers] = useState(minFollowers);

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const section = searchParams.get('section');
        if (section) {
            scrollToElement(section);
        }
    }, [location.search]);

    const {openDrawer} = useDrawer();

    const calculateStats = (followers, engagementRate) => {
        const budget = followers * costPerFollower;
        const engagedUsers = Math.floor(followers * engagementRate);
        return { followers, budget, engagedUsers };
    };

    const instagramStats = useMemo(() => calculateStats(instagramFollowers, instagramEngagementRate), [instagramFollowers]);
    const tiktokStats = useMemo(() => calculateStats(tiktokFollowers, tiktokEngagementRate), [tiktokFollowers]);

    const handleInstagramFollowersChange = (newValue) => {
        setInstagramFollowers(newValue);
    };

    const handleTikTokFollowersChange = (newValue) => {
        setTiktokFollowers(newValue);
    };

    const {data, isLoading, error, refetch} = useApiQuery('collection/1', null, null, {
        enabled: false, // Disable automatic fetching
    });

    useEffect(() => {
        // Fetch data only once when the component mounts
        refetch();
    }, [refetch]);

    const handleCampaignInfo = () => {
        openDrawer(
                <Paragraph style={{padding: '20px'}}>
                Our calculations are based on a cost of 0.25p per engaged user. 
                The engagement rates used are 1% for Instagram and 18% for TikTok, based on recent industry data. 
                Sources: Hootsuite Blog (2023) for Instagram data, IGface (2023) for TikTok data. 
                These figures are averages and actual results may vary based on content quality, audience, and platform algorithms.
                </Paragraph>
        );
    }

    const floatingNavItems = [
        {
            name: 'Info',
            destination: `${window.location.pathname}?section=info`,
            icon: 'IoInformationCircleOutline',
            position: 1,
        },
        {
            name: 'Instagram',
            destination: `${window.location.pathname}?section=instagram`,
            icon: 'FaInstagram',
            position: 2,
        },
        {
            name: 'TikTok',
            destination: `${window.location.pathname}?section=tiktok`,
            icon: 'FaTiktok',
            position: 3,
        },
        {
            name: 'Deliverables',
            destination: `${window.location.pathname}?section=deliverables`,
            icon: 'IoGiftOutline',
            position: 4,
        },
    ];

    return (
        <Container>
            
            <Section style={{padding: '0px', display: 'block'}} id={'intro'}>
                <IntroSection />
            </Section>

            <Section id={'info'}>
                <ImpactTitle main={'Main Title Here'} />
                <Paragraph>Our calculations are based on a cost of 0.25p per engaged user.
                    The engagement rates used are 1% for Instagram and 18% for TikTok, based on recent industry data. </Paragraph>
            </Section>
                
            {/* Section 1: Instagram Followers Slider */}
            <Section id={'instagram'}>
                <ImpactTitle main={'Instagram Campaign'} />

                <div>
                <SliderComponent
                    type={'number'}
                    value={instagramFollowers}
                    max={maxFollowers}
                    min={minFollowers}
                    step={1000}
                    onChange={handleInstagramFollowersChange}
                />
                <Paragraph onClick={() => handleCampaignInfo()} >
                    With an Instagram reach of <strong>{instagramFollowers.toLocaleString()}</strong> followers.
                    The estimated cost for this campaign is <strong>£{(instagramStats.budget.toLocaleString())}</strong>.
                    We estimate <strong>{instagramStats.engagedUsers.toLocaleString()}</strong> engaged users. 
                    Based on a <strong>{instagramEngagementRate * 100}% engagement rate</strong>.
                </Paragraph>

       
                </div>
             
                <ButtonComponent
                    variant={'outlined'}
                    text={`Invest ${convertToCurrency(instagramStats.budget)}`}
                />
            </Section>

            {/* Section 2: TikTok Followers Slider */}
            <Section id={'tiktok'}>
                <ImpactTitle main={'TikTok Campaign'} />
                <div>
                <SliderComponent
                    type={'number'}
                    value={tiktokFollowers}
                    max={maxFollowers}
                    min={minFollowers}
                    step={1000}
                    onChange={handleTikTokFollowersChange}
                />
                <Paragraph onClick={() => handleCampaignInfo()} >
                    With a TikTok reach of <strong>{tiktokFollowers.toLocaleString()}</strong> followers.
                    We estimate <strong>{tiktokStats.engagedUsers.toLocaleString()}</strong> engaged users.
                    The estimated cost for this campaign is <strong>{convertToCurrency(tiktokStats.budget)}</strong>.
                    Based on a <strong>{tiktokEngagementRate * 100}% engagement rate</strong>. Read More.
                </Paragraph>
                
                </div>


               <ButtonComponent
                    variant={'outlined'}
                    text={`Invest ${convertToCurrency(tiktokStats.budget)}`}
                />
            </Section>


            {/* Section 3: Deliverables */}
            <Section id={'deliverables'}>

                <ImpactTitle main={'Deliverables'} />
                <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. 
                    Nulla facilisi. Sed ut purus eget sapien. Nulla facilisi. Sed ut purus eget sapien. 
                    Nulla facilisi. Sed ut purus eget sapien. Nulla facilisi. Sed ut purus eget sapien. 
                    Nulla facilisi. Sed ut purus eget sapien. Nulla.
                </Paragraph>

                {isLoading && <p>Loading products...</p>}
                {error && <p>Error: {error.message}</p>}
                {<ProductCollection data={data?.data} />}
                <ButtonComponent
                    variant={'solid'}
                    text={`Invest ${convertToCurrency(instagramStats.budget + tiktokStats.budget)}`}
                />
            </Section>

            <FloatingNav items={floatingNavItems} />
        </Container>
    );
};

CampaignPurchaseLandingPage.propTypes = {
    
};

export default CampaignPurchaseLandingPage;
